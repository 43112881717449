<template>
  <v-layout>
    <v-app-bar flat dark app clipped-left height="50" color="primary">
      <v-icon class="mr-2" @click.stop="drawer = !drawer" v-if="drawer == true"
        >mdi-menu-open</v-icon
      >
      <v-icon class="mr-2" @click.stop="drawer = !drawer" v-if="drawer == false"
        >mdi-menu-close</v-icon
      >
      <v-toolbar-title>{{ courseData.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon size="30" @click="$router.push('/home/teacher-courses')"
        >mdi-close-circle</v-icon
      >
    </v-app-bar>
    <v-sheet>
      <v-navigation-drawer app clipped width="22%" v-model="drawer">
        <v-list v-if="courseData && courseData.chapters">
          <v-list-group
            :value="true"
            v-for="chapter in courseData.chapters"
            :key="chapter.chapterNo"
            prepend-icon="$expand"
            sub-group
            class="ml-n6"
          >
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-content class="ml-n7">
                  <v-list-item-title class="text-h5 font-weight-medium">{{
                    chapter.chapterName
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-group
              :value="true"
              v-for="lesson in chapter.lessons"
              :key="lesson.lessonNo"
              no-action
              sub-group
            >
              <template v-slot:activator>
                <v-list-item-content class="ml-n4">
                  <v-list-item-title
                    class="text-wrap error--text text-body-1"
                    >{{ lesson.lessonName }}</v-list-item-title
                  >
                </v-list-item-content>
              </template>
              <v-list-group :value="true" no-action sub-group class="ml-3">
                <template v-slot:activator>
                  <v-list-item-content class="ml-n4">
                    <v-list-item-title
                      class="text-body-2 font-weight-medium accent--text"
                      >Content</v-list-item-title
                    >
                  </v-list-item-content>
                </template>
                <v-list-item-group
                  :active-class="'disable-current-active-element'"
                >
                  <v-list-item
                    v-for="content in lesson.content"
                    :key="content.contentNo"
                    link
                    :class="
                      highLightCurrentContent(
                        chapter.chapterNo,
                        lesson.lessonNo,
                        content.contentNo
                      )
                        ? 'disable-current-active-element ml-4'
                        : 'ml-4'
                    "
                    @click="
                      showContent(content, chapter.chapterNo, lesson.lessonNo)
                    "
                    :input-value="
                      highLightCurrentContent(
                        chapter.chapterNo,
                        lesson.lessonNo,
                        content.contentNo
                      )
                        ? 'disable-current-active-element'
                        : ''
                    "
                  >
                    <!-- :class="{
                            active: activeContentElement === content.source,
                          }" -->
                    <v-list-item-icon>
                      <v-icon small color="black">
                        {{ `mdi-${getIcon(content)}` }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="ml-n3">
                      <v-list-item-title class="text-wrap text-body-3">
                        {{ content.title }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list-group>
              <v-list-group
                :value="true"
                no-action
                sub-group
                class="ml-3"
                v-if="
                  lesson.notes.length != 0 &&
                  courseData.courseType == COURSE_TYPE_PRIVATE
                "
              >
                <template v-slot:activator>
                  <v-list-item-content class="ml-n4">
                    <v-list-item-title
                      class="text-body-2 font-weight-medium accent--text"
                      >Notes</v-list-item-title
                    >
                  </v-list-item-content>
                </template>
                <v-list-item-group
                  :active-class="'disable-current-active-element'"
                >
                  <v-list-item
                    v-for="note in lesson.notes"
                    :key="note.noteNo"
                    link
                    class="ml-4"
                    @click="showNotes(note, chapter.chapterNo, lesson.lessonNo)"
                    :input-value="
                      highLightCurrentNote(
                        chapter.chapterNo,
                        lesson.lessonNo,
                        note.noteNo
                      )
                        ? 'disable-current-active-element'
                        : ''
                    "
                  >
                    <v-list-item-icon>
                      <v-icon small color="black">
                        {{ `mdi-${getIcon(note)}` }}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="ml-n3">
                      <v-list-item-title class="text-wrap text-body-3">{{
                        note.title
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list-group>

              <v-list-group
                :value="true"
                no-action
                sub-group
                class="ml-3"
                v-if="
                  lesson.codeAssignments.length != 0 &&
                  courseData.courseType == COURSE_TYPE_PRIVATE
                "
              >
                <template v-slot:activator>
                  <v-list-item-content class="ml-n4">
                    <v-list-item-title
                      class="text-body-2 font-weight-medium accent--text"
                      >Code Assignments</v-list-item-title
                    >
                  </v-list-item-content>
                </template>
                <v-list-item-group
                  :active-class="'disable-current-active-element'"
                >
                  <v-list-item
                    v-for="assignment in lesson.codeAssignments"
                    :key="assignment.assignmentNumber"
                    link
                    class="ml-4"
                    @click="
                      showCode(chapter.chapterNo, lesson.lessonNo, assignment)
                    "
                    :input-value="
                      highLightCurrentCode(
                        chapter.chapterNo,
                        lesson.lessonNo,
                        assignment,
                        assignment.assignmentNumber
                      )
                        ? 'disable-current-active-element'
                        : ''
                    "
                  >
                    <v-list-item-icon>
                      <v-icon small style="color: black">
                        mdi-code-array
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="ml-n3">
                      <v-row>
                        <v-col>
                          <v-list-item-title class="text-wrap text-body-3">{{
                            assignment.title
                          }}</v-list-item-title>
                        </v-col>
                        <v-col cols="4">
                          <v-chip
                            v-if="assignment.isHomework == true"
                            x-small
                            color="error"
                            >HW</v-chip
                          >
                          <v-chip
                            v-else-if="assignment.isHomework == false"
                            x-small
                            color="primary"
                            >CW</v-chip
                          >
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list-group>

              <v-list-group
                :value="true"
                no-action
                sub-group
                class="ml-3"
                v-if="
                  lesson.quizAssignments.length != 0 &&
                  courseData.courseType == COURSE_TYPE_PRIVATE
                "
              >
                <template v-slot:activator>
                  <v-list-item-content class="ml-n4">
                    <v-list-item-title
                      class="text-body-2 font-weight-medium accent--text"
                      >Quiz Assignments</v-list-item-title
                    >
                  </v-list-item-content>
                </template>
                <v-list-item-group
                  :active-class="'disable-current-active-element'"
                >
                  <v-list-item
                    v-for="assignment in lesson.quizAssignments"
                    :key="assignment.assignmentNumber"
                    link
                    class="ml-4"
                    @click="
                      showQuiz(chapter.chapterNo, lesson.lessonNo, assignment)
                    "
                    :input-value="
                      highLightCurrentQuiz(
                        chapter.chapterNo,
                        lesson.lessonNo,
                        assignment,
                        assignment.assignmentNumber
                      )
                        ? 'disable-current-active-element'
                        : ''
                    "
                  >
                    <v-list-item-icon>
                      <v-icon small style="color: black">
                        mdi-lightbulb-on-50
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="ml-n3">
                      <v-row>
                        <v-col>
                          <v-list-item-title class="text-wrap text-body-3">{{
                            assignment.quizName
                          }}</v-list-item-title>
                        </v-col>
                        <v-col cols="4">
                          <v-chip
                            v-if="assignment.isHomework == true"
                            x-small
                            color="error"
                            >HW</v-chip
                          >
                          <v-chip
                            v-else-if="assignment.isHomework == false"
                            x-small
                            color="primary"
                            >CW</v-chip
                          >
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list-group>
              <v-list-group
                :value="true"
                no-action
                sub-group
                class="ml-3"
                v-if="
                  lesson.textAssignments.length != 0 &&
                  courseData.courseType == COURSE_TYPE_PRIVATE
                "
              >
                <template v-slot:activator>
                  <v-list-item-content class="ml-n4">
                    <v-list-item-title
                      class="text-body-2 font-weight-medium accent--text"
                      >Text Assignments</v-list-item-title
                    >
                  </v-list-item-content>
                </template>
                <v-list-item-group
                  :active-class="'disable-current-active-element'"
                >
                  <v-list-item
                    v-for="assignment in lesson.textAssignments"
                    :key="assignment.assignmentNumber"
                    link
                    class="ml-4"
                    @click="
                      showText(chapter.chapterNo, lesson.lessonNo, assignment)
                    "
                    :input-value="
                      highLightCurrentText(
                        chapter.chapterNo,
                        lesson.lessonNo,
                        assignment,
                        assignment.assignmentNumber
                      )
                        ? 'disable-current-active-element'
                        : ''
                    "
                  >
                    <v-list-item-icon>
                      <v-icon small style="color: black">
                        mdi-text-box-edit-outline
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="ml-n3">
                      <v-row>
                        <v-col>
                          <v-list-item-title class="text-wrap text-body-3">{{
                            assignment.title
                          }}</v-list-item-title>
                        </v-col>
                        <v-col cols="4">
                          <v-chip
                            v-if="assignment.isHomework == true"
                            x-small
                            color="error"
                            >HW</v-chip
                          >
                          <v-chip
                            v-else-if="assignment.isHomework == false"
                            x-small
                            color="primary"
                            >CW</v-chip
                          >
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list-group>

              <v-list-group
                :value="true"
                no-action
                sub-group
                class="ml-3"
                v-if="
                  lesson.codelyAssignments.length != 0 &&
                  courseData.courseType == COURSE_TYPE_PRIVATE
                "
              >
                <template v-slot:activator>
                  <v-list-item-content class="ml-n4">
                    <v-list-item-title
                      class="text-body-2 font-weight-medium accent--text"
                      >Codely Assignments</v-list-item-title
                    >
                  </v-list-item-content>
                </template>
                <v-list-item-group
                  :active-class="'disable-current-active-element'"
                >
                  <v-list-item
                    v-for="assignment in lesson.codelyAssignments"
                    :key="assignment.assignmentNumber"
                    link
                    class="ml-4"
                    @click="
                      showCodely(chapter.chapterNo, lesson.lessonNo, assignment)
                    "
                    :input-value="
                      highLightCurrentCodely(
                        chapter.chapterNo,
                        lesson.lessonNo,
                        assignment,
                        assignment.assignmentNumber
                      )
                        ? 'disable-current-active-element'
                        : ''
                    "
                  >
                    <v-list-item-icon>
                      <v-icon small style="color: black">
                        mdi-code-block-brackets
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="ml-n3">
                      <v-row>
                        <v-col>
                          <v-list-item-title class="text-wrap text-body-3">{{
                            assignment.title
                          }}</v-list-item-title>
                        </v-col>
                        <v-col cols="4">
                          <v-chip
                            v-if="assignment.isHomework == true"
                            x-small
                            color="error"
                            >HW</v-chip
                          >
                          <v-chip
                            v-else-if="assignment.isHomework == false"
                            x-small
                            color="primary"
                            >CW</v-chip
                          >
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list-group>
            </v-list-group>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>
    </v-sheet>
    <v-main
      style="
        background-color: #f4f7fe;
        overflow: hidden !important;
        overflow-y: auto;
      "
      class="pb-1"
    >
      <div
        v-if="content"
        class="px-3 pt-3"
        style="max-height: calc(100% - 0.8rem) !important; height: 100%"
      >
        <v-menu
          v-model="fab"
          offset-x
          top
          offset-y
          persistent
          :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <div
              :class="expand_chatbot ? 'chatbot-container' : 'closed-container'"
            >
              <v-btn
                fab
                @click="minimiseChatbot($event)"
                small
                class="mt-3"
                text
              >
                <v-icon v-if="!expand_chatbot"> mdi-step-backward </v-icon>
                <v-icon v-else size="30"> mdi-step-forward </v-icon>
              </v-btn>
              <v-btn
                color="primary"
                fab
                v-bind="attrs"
                v-on="on"
                class="mr-3 fixed-btn"
                style="bottom: 4.2rem"
                v-if="expand_chatbot"
              >
                <v-icon v-if="fab"> mdi-close </v-icon>
                <v-avatar v-else size="42">
                  <img src="../assets/Icons/Group_227.svg" />
                </v-avatar>
              </v-btn>
            </div>
          </template>
          <ChatBox></ChatBox>
        </v-menu>
        <v-card flat style="min-height: calc(100%) !important" class="mb-7">
          <div
            v-if="
              ((currentActiveElement.source != 'Content locked' ||
                currentActiveElement.source != 'Notes locked') &&
                currentActiveElement.type == CONTENT_TYPE_TEXT) ||
              ((currentActiveElement.source != 'Notes locked' ||
                currentActiveElement.source != 'Content locked') &&
                currentActiveElement.type == CONTENT_TYPE_MEDIA &&
                currentActiveElement.resource?.status == 'BLOCKED')
            "
          >
            <CourseTextView
              style="position: absolute; inset: 0; margin: 0.35rem"
              :htmlContent="currentActiveElement.source"
            />
          </div>
          <!-- Video player will be shown if content.resource.mimeType contains 'video' string -->
          <div
            v-if="
              (currentActiveElement.source != 'Content locked' &&
                currentActiveElement.type === CONTENT_TYPE_MEDIA &&
                currentActiveElement.resource?.status != 'BLOCKED' &&
                currentActiveElement.resource?.mimeType?.includes('video')) ||
              (currentActiveElement.source != 'Notes locked' &&
                currentActiveElement.type === CONTENT_TYPE_MEDIA &&
                currentActiveElement.resource?.status != 'BLOCKED' &&
                currentActiveElement.resource?.mimeType?.includes('video'))
            "
          >
            <VideoPlayer
              style="position: absolute; inset: 0; margin: 0.35rem"
              :options="{
                autoplay: true,
                controls: true,
                playbackRates: [0.5, 1, 1.5, 2],
                sources: [
                  {
                    src: `${currentActiveElement.source}`,
                    type: 'video/mp4',
                  },
                ],
              }"
              :key="currentActiveElement.title"
            ></VideoPlayer>
          </div>
          <!-- Vue friendly Iframe will be shown if 
            content.resource.mimeType  does not contain 'video' string -->
          <div
            v-if="
              (currentActiveElement.source != 'Content locked' &&
                currentActiveElement.type == CONTENT_TYPE_MEDIA &&
                currentActiveElement.resource?.status != 'BLOCKED' &&
                !currentActiveElement.resource?.mimeType?.includes('video')) ||
              (currentActiveElement.source != 'Notes locked' &&
                currentActiveElement.type == CONTENT_TYPE_MEDIA &&
                currentActiveElement.resource?.status != 'BLOCKED' &&
                !currentActiveElement.resource?.mimeType?.includes('video'))
            "
          >
            <div v-if="imagesArray">
              <ImageArray
                style="position: absolute; inset: 0; margin: 0.35rem"
                :title="currentActiveElement.title"
                :images="currentActiveElement.images"
              />
            </div>
            <div v-else>
              <CourseMediaView
                style="position: absolute; inset: 0; margin: 0.35rem"
                :scaleFactor="0.9"
                :src="
                  currentActiveElement.resource?.mimeType?.includes(
                    'application'
                  )
                    ? currentActiveElement.source + '#toolbar=0'
                    : currentActiveElement.source
                "
              />
            </div>
          </div>
          <div
            v-if="
              currentActiveElement.source != 'Content locked' &&
              currentActiveElement.type == CONTENT_TYPE_CODELY
            "
          >
            <CourseCodelyContent
              style="position: absolute; inset: 0; margin: 0.35rem"
              :src="currentActiveElement.source"
              :scaleFactor="0.9"
            />
          </div>

          <div v-if="showCodeAssignment == true">
            <TeacherCourseCodeAssignmentView
              style="position: absolute; inset: 0; margin: 0.35rem"
              :key="Date.now()"
              :assignment="currentActiveElement"
            />
          </div>

          <div v-if="showCodelyAssignment == true">
            <TeacherCourseCodelyAssignmentView
              style="position: absolute; inset: 0; margin: 0.35rem"
              :scaleFactor="0.8"
              :key="Date.now()"
              :assignment="currentActiveElement"
            />
          </div>

          <div v-if="showTextAssignment == true">
            <TeacherCourseTextAssignmentView
              style="position: absolute; inset: 0; margin: 0.35rem"
              :key="Date.now()"
              :assignment="currentActiveElement"
            />
          </div>
          <div
            v-if="
              currentActiveElement.quizId ||
              (currentActiveElement.source != 'Content locked' &&
                currentActiveElement.type == CONTENT_TYPE_QUIZ)
            "
          >
            <TeacherCourseQuizView
              style="position: absolute; inset: 0; margin: 0.35rem"
              :key="Date.now()"
              :quizData="currentActiveElement"
            />
          </div>
        </v-card>
      </div>
    </v-main>
  </v-layout>
</template>

<script>
import {
  COURSE_TYPE_PRIVATE,
  CONTENT_TYPE_TEXT,
  CONTENT_TYPE_MEDIA,
  CONTENT_TYPE_CODELY,
  CONTENT_TYPE_QUIZ,
} from "@/common/constants";
import { GET_PARAMS_VALUE } from "@/store/getters";
import { mapGetters } from "vuex";
import ChatBox from "./ChatBox.vue";
import VideoPlayer from "./VideoPlayer.vue";
import CourseCodelyContent from "@/components/CourseViewComponents/CourseCodelyContent.vue";
import ImageArray from "./ImageArray2.vue";
import CourseMediaView from "@/components/CourseViewComponents/CourseMediaView.vue";
import CourseTextView from "@/components/CourseViewComponents/CourseTextView.vue";
import TeacherCourseCodeAssignmentView from "@/components/CourseViewComponents/TeacherCourseCodeAssignmentView.vue";
import TeacherCourseCodelyAssignmentView from "@/components/CourseViewComponents/TeacherCourseCodelyAssignmentView.vue";
import TeacherCourseTextAssignmentView from "@/components/CourseViewComponents/TeacherCourseTextAssignmentView.vue";
import TeacherCourseQuizView from "@/components/CourseViewComponents/TeacherCourseQuizView.vue";

export default {
  data() {
    return {
      courseData: {},
      drawer: true,
      currentActiveElement: null,
      content: null,
      CONTENT_TYPE_TEXT: CONTENT_TYPE_TEXT,
      CONTENT_TYPE_QUIZ: CONTENT_TYPE_QUIZ,
      CONTENT_TYPE_MEDIA: CONTENT_TYPE_MEDIA,
      CONTENT_TYPE_CODELY: CONTENT_TYPE_CODELY,
      COURSE_TYPE_PRIVATE: COURSE_TYPE_PRIVATE,
      disableNextButton: false,
      disablePreviousButton: false,
      imagesArray: false,
      loading: false,
      fab: false,
      expand_chatbot: true,
      showCodelyAssignment: false,
      showTextAssignment: false,
      showCodeAssignment: false,
    };
  },
  computed: {
    ...mapGetters({
      paramsValue: `schoolModule/${GET_PARAMS_VALUE}`,
    }),
  },
  components: {
    ChatBox,
    VideoPlayer,
    CourseCodelyContent,
    ImageArray,
    CourseMediaView,
    CourseTextView,
    TeacherCourseCodeAssignmentView,
    TeacherCourseCodelyAssignmentView,
    TeacherCourseTextAssignmentView,
    TeacherCourseQuizView,
  },
  mounted: function () {
    this.courseData = this.paramsValue.courseData;
    this.content = this.courseData.chapters[0].lessons[0].content[0];

    const initialContent = this.courseData.chapters[0].lessons[0].content[0];
    this.content = initialContent;
    this.showContent(initialContent, 1, 1);
    // if (initialContent.type === CONTENT_TYPE_QUIZ) {
    //   this.getQuizBYQuizId(initialContent.source);
    //   this.showContent(initialContent, 1, 1);
    // }
  },
  methods: {
    getIcon(content) {
      if (content.type == CONTENT_TYPE_QUIZ) {
        return "lightbulb-on-10";
      } else if (content.type == CONTENT_TYPE_TEXT) {
        return "text-box-outline";
      } else if (content.type == CONTENT_TYPE_MEDIA) {
        if (
          content.resource?.mimeType &&
          content.resource?.mimeType.includes("video")
        ) {
          return "video";
        } else if (
          content.resource?.mimeType &&
          content.resource?.mimeType.includes("image")
        ) {
          return "image";
        } else if (
          content.resource?.mimeType &&
          content.resource?.mimeType.includes("application")
        ) {
          return "file-pdf-box";
        } else {
          return "table-of-contents";
        }
      } else {
        return "code-block-braces";
      }
    },
    highLightCurrentContent(chapterNo, lessonNo, contentNo) {
      return (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo &&
        contentNo == this.currentActiveElement.contentNo
      );
    },
    highLightCurrentNote(chapterNo, lessonNo, noteNo) {
      return (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo &&
        noteNo == this.currentActiveElement.noteNo
      );
    },
    highLightCurrentCode(chapterNo, lessonNo, assignment, assignmentNumber) {
      return (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo &&
        this.currentActiveElement == assignment &&
        assignmentNumber == this.currentActiveElement.assignmentNumber
      );
    },
    highLightCurrentQuiz(chapterNo, lessonNo, assignment, assignmentNumber) {
      return (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo &&
        this.currentActiveElement == assignment &&
        assignmentNumber == this.currentActiveElement.assignmentNumber
      );
    },
    highLightCurrentText(chapterNo, lessonNo, assignment, assignmentNumber) {
      return (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo &&
        this.currentActiveElement == assignment &&
        assignmentNumber == this.currentActiveElement.assignmentNumber
      );
    },
    highLightCurrentCodely(chapterNo, lessonNo, assignment, assignmentNumber) {
      return (
        this.currentActiveElement.chapterNo == chapterNo &&
        this.currentActiveElement.lessonNo == lessonNo &&
        this.currentActiveElement == assignment &&
        assignmentNumber == this.currentActiveElement.assignmentNumber
      );
    },

    showContent(content, chapterNo, lessonNo) {
      // this.currentActiveElement = null;
      this.currentActiveElement = content;
      this.currentActiveElement.chapterNo = chapterNo;
      this.currentActiveElement.lessonNo = lessonNo;
      this.showSelectedNote = false;
      this.showCodeAssignment = false;
      this.showTextAssignment = false;
      this.showCodelyAssignment = false;

      if (
        content.type === CONTENT_TYPE_TEXT ||
        content.type === CONTENT_TYPE_MEDIA ||
        content.type === CONTENT_TYPE_CODELY
      ) {
        if (content.images && content.images.length > 0) {
          this.imagesArray = true;
        } else {
          this.imagesArray = false;
        }
        this.note = {};
        this.quizContent = {};
        this.quizContent.questions = [];
        this.textAssignment = {};
        this.codeAssignment = {};
        this.codelyAssignment = {};
        this.content = content;
        content.value = true;
        this.showSelectedContent = true;
      }
      if (content.type === CONTENT_TYPE_QUIZ) {
        // this.getQuizBYQuizId(content.source);
        this.content = content;
        content.value = true;
        this.showSelectedContent = true;
      }
    },

    showNotes(note, chapterNo, lessonNo) {
      // this.currentActiveElement = null;
      this.currentActiveElement = note;
      this.currentActiveElement.chapterNo = chapterNo;
      this.currentActiveElement.lessonNo = lessonNo;
      if (
        note.source === "Notes locked" ||
        note.type === CONTENT_TYPE_TEXT ||
        note.type === CONTENT_TYPE_MEDIA ||
        note.type === CONTENT_TYPE_CODELY
      ) {
        if (note.images && note.images.length > 0) {
          this.imagesArray = true;
        } else {
          this.imagesArray = false;
        }
        this.content = {};
        this.quizContent = {};
        this.quizContent.questions = [];
        this.textAssignment = {};
        this.codeAssignment = {};
        this.codelyAssignment = {};
        this.showSelectedContent = false;
        this.showCodeAssignment = false;
        this.showTextAssignment = false;
        this.showCodelyAssignment = false;
        this.note = note;
        note.value = true;
        this.showSelectedNote = true;
        if (note.type === CONTENT_TYPE_CODELY) {
          window.open(note.source, "_blank");
        }
      }
    },

    showCode(chapterNo, lessonNo, assignment) {
      // this.currentActiveElement = null;
      this.currentActiveElement = assignment;
      this.currentActiveElement.chapterNo = chapterNo;
      this.currentActiveElement.lessonNo = lessonNo;
      this.content = {};
      this.note = {};
      this.codelyAssignment = {};
      this.codeAssignment = {};
      this.textAssignment = {};
      this.showSelectedContent = false;
      this.showSelectedNote = false;
      this.showTextAssignment = false;
      this.showCodeAssignment = false;
      this.showCodelyAssignment = false;
      this.quizContent = {};
      this.quizContent.questions = [];
      assignment.value = true;
      this.showCodeAssignment = true;
      this.codeAssignment = assignment;
    },

    showQuiz(chapterNo, lessonNo, assignment) {
      // this.currentActiveElement = null;
      this.currentActiveElement = assignment;
      this.currentActiveElement.chapterNo = chapterNo;
      this.currentActiveElement.lessonNo = lessonNo;
      this.content = {};
      this.note = {};
      this.codelyAssignment = {};
      this.codeAssignment = {};
      this.textAssignment = {};
      this.showSelectedContent = false;
      this.showSelectedNote = false;
      this.showTextAssignment = false;
      this.showCodeAssignment = false;
      this.showCodelyAssignment = false;
      this.quizContent = {};
      this.quizContent.questions = [];
      assignment.value = true;
      // this.getQuizBYQuizId(assignment.quizId);
    },

    showText(chapterNo, lessonNo, assignment) {
      // this.currentActiveElement = null;
      this.currentActiveElement = assignment;
      this.currentActiveElement.chapterNo = chapterNo;
      this.currentActiveElement.lessonNo = lessonNo;
      this.content = {};
      this.note = {};
      this.codelyAssignment = {};
      this.codeAssignment = {};
      this.textAssignment = {};
      this.showSelectedContent = false;
      this.showSelectedNote = false;
      this.showTextAssignment = false;
      this.showCodeAssignment = false;
      this.showCodelyAssignment = false;
      this.quizContent = {};
      this.quizContent.questions = [];
      assignment.value = true;
      this.showTextAssignment = true;
      this.textAssignment = assignment;
    },

    showCodely(chapterNo, lessonNo, assignment) {
      // this.currentActiveElement = null;
      this.currentActiveElement = assignment;
      this.currentActiveElement.chapterNo = chapterNo;
      this.currentActiveElement.lessonNo = lessonNo;
      this.content = {};
      this.note = {};
      this.codelyAssignment = {};
      this.codeAssignment = {};
      this.textAssignment = {};
      this.showSelectedContent = false;
      this.showSelectedNote = false;
      this.showTextAssignment = false;
      this.showCodeAssignment = false;
      this.showCodelyAssignment = false;
      this.quizContent = {};
      this.quizContent.questions = [];
      assignment.value = true;
      this.showCodelyAssignment = true;
      this.codelyAssignment = assignment;
    },
    // getQuizBYQuizId(quizId) {
    //   const url = GET_QUIZ_BY_ID;
    //   const payload = {};
    //   payload.quizId = quizId;
    //   this.index = 0;
    //   // this.selected = false;
    //   this.setOverlayValue(true);
    //   axios
    //     .post(url, payload, getAxiosOptions(BASE_URL, this.token))
    //     .then((res) => {
    //       this.quizContent = res.data;
    //       this.content = {};
    //       this.note = {};
    //       this.codelyAssignment = {};
    //       this.codeAssignment = {};
    //       this.textAssignment = {};
    //       this.showCodelyAssignment = false;
    //       this.showTextAssignment = false;
    //       this.showCodeAssignment = false;
    //       // this.selected = true;
    //       this.setOverlayValue(false);
    //     })
    //     .catch((error) => {
    //       error = error.errorMessage;
    //       this.setOverlayValue(false);
    //     });
    // },
    minimiseChatbot(event) {
      this.expand_chatbot = !this.expand_chatbot;
      event.currentTarget.blur();
    },
  },
};
</script>
<style>
.fixed-btn {
  z-index: 10;
  position: fixed;
  bottom: 6rem;
  right: 1.3rem;
}
.chatbot-container {
  position: fixed;
  bottom: 4rem;
  right: 0rem;
  width: 8rem;
  height: 3.8rem;
  background-color: rgba(250, 175, 25, 0);
  border-radius: 3rem 0 0 3rem !important;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 10;
}
.closed-container {
  position: fixed;
  bottom: 4rem;
  right: 0rem;
  width: 2rem;
  height: 3.8rem;
  background-color: rgba(250, 175, 25, 0);
  border-radius: 3rem 0 0 3rem !important;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 10;
}
.disable-current-active-element {
  pointer-events: none;
  border: 1px solid #714eff !important;
  background-color: #efebff !important;
}
</style>
